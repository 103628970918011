import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

import { UserInformation, UserState } from './user.types';

const userInitialState: UserState = {
	user: {
		name: '',
		email: '',
		givenName: '',
		groups: [],
		sub: '',
	},
	selectedGroupId: '',
};

export const UserStore = signalStore(
	{ providedIn: 'root' },
	withState(userInitialState),
	withMethods((store) => ({
		setSelectedGroupId(groupId: string) {
			patchState(store, (state) => ({
				...state,
				selectedGroupId: groupId,
			}));
		},
		updateUser(userData: Partial<UserInformation>) {
			patchState(store, (state) => ({ ...state, user: { ...state.user, ...userData } }));
		},
	})),
);
