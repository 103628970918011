import { computed } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { CompanyGroup, GroupsState, UserGroup } from './groups.types';

const groupsInitialState: GroupsState = {
	groups: {},
	selectedGroupId: '',
};

export const GroupsStore = signalStore(
	{ providedIn: 'root' },
	withState(groupsInitialState),
	withComputed((store) => ({
		groupsList: computed(() => {
			return Object.values(store.groups() || {});
		}),
		selectedGroup: computed<CompanyGroup | undefined>(() => {
			return store.groups()?.[store.selectedGroupId()];
		}),
	})),
	withMethods((store) => ({
		addGroup(group: CompanyGroup) {
			patchState(store, (state) => ({
				...state,
				groups: {
					...state.groups,
					[group.access]: { ...(state.groups[group.access] || {}), ...group },
				},
			}));
		},
		addGroupUsers(groupId: string, usersList: UserGroup[]) {
			patchState(store, (state) => ({
				...state,
				groups: {
					...state.groups,
					[groupId]: {
						...state.groups[groupId],
						usersList,
					},
				},
			}));
		},
		getGroup(groupId: string) {
			return store.groups()?.[groupId];
		},
		removeGroup(groupId: string) {
			patchState(store, (state) => {
				const groups = { ...state.groups };
				delete groups[groupId];

				return {
					...state,
					groups,
				};
			});
		},
		removeGroupUser(groupId: string, userId: string) {
			patchState(store, (state) => ({
				...state,
				groups: {
					...state.groups,
					[groupId]: {
						...state.groups[groupId],
						usersList: state.groups[groupId].usersList?.filter((u) => u.user.sub !== userId),
					},
				},
			}));
		},
		setSelectedGroupId(id: string) {
			patchState(store, (state) => ({
				...state,
				selectedGroupId: id,
			}));
		},
		updateGroup(groupId: string, group: Partial<CompanyGroup>) {
			patchState(store, (state) => ({
				...state,
				groups: {
					...state.groups,
					[groupId]: {
						...state.groups[groupId],
						...group,
					},
				},
			}));
		},
		updateUser(groupId: string, userId: string, user: Partial<UserGroup>) {
			patchState(store, (state) => {
				const newUsersList = [...(state.groups[groupId]?.usersList || [])];
				const elementIndex = newUsersList.findIndex((u) => u.user.sub === userId);

				newUsersList[elementIndex] = {
					...newUsersList[elementIndex],
					...user,
				};

				return {
					...state,
					groups: {
						...state.groups,
						[groupId]: {
							...state.groups[groupId],
							usersList: newUsersList,
						},
					},
				};
			});
		},
	})),
);
